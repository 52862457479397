import { msalResult } from "../authConfig";
import { Axiosclient } from "./Axiosclient";


export const AxiosService = (token?: any, lng?: any, multiform?: any) => {

  const { apim } = msalResult;  

  const normalFormHeader = {
    'Content-Type' : 'application/json',
    'Ocp-Apim-Subscription-Key': apim?.apimKey
  }
  
  const normalLngHeader = {
    'Content-Type' : 'text/string',
    'Ocp-Apim-Subscription-Key': apim?.apimKey
  }
  
  const normalMultiformHeader = {
    'Content-Type': 'multipart/form-data',
    'Ocp-Apim-Subscription-Key': apim?.apimKey
  }

    const getCurrentAccessToken = token;
    const axiosClient =  Axiosclient({
      options: {
        baseURL: apim?.apiURL,
        timeout: 300000,
        headers: lng === true ? normalLngHeader :( multiform ? normalMultiformHeader : normalFormHeader)
      },
      getCurrentAccessToken
    });
    return axiosClient;
  }

export default AxiosService
