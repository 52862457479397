import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIdToken } from '../../authConfig';
import { SetLanguagePreference } from '../../services/ApiService';

interface LanguageContextType {
  language: string; // Current language in use
  setGlobalLanguage: (lang: string) => void; // Updates global language state
  updateLanguagePreference: (lang: string) => Promise<void>; // Updates API
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>('');
  const { i18n, ready } = useTranslation();

  const setGlobalLanguage = (lang: string) => {
    i18n.changeLanguage(lang); // Update translation library
    setLanguage(lang); // Update global context state
  };

  const updateLanguagePreference = async (lang: string) => {
    const tokenAccess = await getIdToken(); // Fetch access token
    await SetLanguagePreference(lang, tokenAccess); // Update preference on the server
  };

  return (
    <LanguageContext.Provider value={{ language, setGlobalLanguage, updateLanguagePreference }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
function setlngprefence(arg0: boolean) {
    throw new Error('Function not implemented.');
}

