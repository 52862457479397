import React, { useEffect, useState } from 'react';
import './App.css';
import { PageContainer,  } from './components/PageContainer/pageContainer';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { getIdToken, initializeMsal, msalResult, } from './authConfig';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ConsentForm from './components/ConsentForm/ConsentForm';
import { LinearProgress } from '@mui/material';
import { GetUsersIdentity } from './services/ApiService';
import { useLanguage } from './components/Language/LanguageContext';
import { useTranslation } from 'react-i18next';

function App() {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [initialConsentVisible, setInitialConsentVisible] = useState(true);
  const [lngpreference, setLngPreference] = useState<boolean>();
  const [isInitialized, setIsInitialized] = useState(false); // Track initialization
  const {language, setGlobalLanguage} = useLanguage();
  const { i18n } = useTranslation();
  const { accounts } = useMsal();

  const openConsentForm = () =>{
   return setOpen(true);
  } 

  const { theme } = msalResult;

  const { instance } = msalResult;

  const redirectToWebsite = (url: any) => {
    window.open(url, "_blank");
  }

  const GetUserIdentityInformation = async () => {
    try {
      const tokenAccess = await getIdToken();
      const userIdentity = await GetUsersIdentity(tokenAccess);
      const preferredLanguage = userIdentity?.data?.preferredLanguage;

      // Set global language and preference
      if (preferredLanguage === 'sv' || preferredLanguage === 'no') {
        setGlobalLanguage(preferredLanguage);
        setLngPreference(true);
      } else {
        setGlobalLanguage('en');
        setLngPreference(false);
      }
    } catch (error) {
      console.error('Error fetching user identity:', error);
    }
  };
 

  const GetUserIdentityInformatio = async () => {
    const tokenAccess = await getIdToken();
    GetUsersIdentity(tokenAccess).then((r: any) => {
      if(r?.data?.preferredLanguage === "sv" || r?.data?.preferredLanguage === "no"){
        setGlobalLanguage(r?.data?.preferredLanguage);
        // setlngprefence(true);
      } else {
        setGlobalLanguage("en");
        // setlngprefence(false)                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      }
    })
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await initializeMsal();
        setLoading(false);
        setIsInitialized(true);
      } catch (error) {
        console.error('Error during MSAL initialization:', error);
        setLoading(false);
      }
    };

    initialize();
  }, []);

  
  useEffect(() => {
    if (isInitialized && accounts.length > 0) {
      const timer = setTimeout(() => {
        GetUserIdentityInformation();
      }, 5000); 
        return () => clearTimeout(timer);
    }
  }, [isInitialized]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage') || 'en'; 

    if (savedLanguage === 'no' || savedLanguage === 'sv') {
      setLngPreference(true);
    } else {
      setLngPreference(false);
    }

    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  useEffect(() => {
    if (language === 'no' || language === 'sv') {
      setLngPreference(true);
      localStorage.setItem('preferredLanguage', language); 
    } else {
      setLngPreference(false);
      localStorage.setItem('preferredLanguage', 'en');
    }
  }, [language]); 

  if (loading) {
    return( 
      <div style={{display:'flex', justifyContent:'center', flexDirection:'row'}}>
        <div style={{width:'100%', marginTop:'`%'}}>
          <LinearProgress/>
        </div>
      </div>
      )
  }
  
  if (!msalResult) {
    // Handle case where initialization failed
    return <div>Error initializing MSAL</div>;
  }    

  const handleCloseConsentForm = () => {
    return setOpen(false);
  }

  const handleAccept = () => {
    Cookies.set('myAppCookieConsent', 'true', { expires: 150 });
    setInitialConsentVisible(false);
  };

  const handleDecline = () => {
      Cookies.remove('functional');
      Cookies.remove('marketing');
      Cookies.set('myAppCookieConsent', 'false', { expires: 150 });
      setInitialConsentVisible(false);
  };

  return (
    <MsalProvider instance={instance}>
      {
        initialConsentVisible && (
            <CookieConsent
              location="bottom"
              buttonText="Confirm"
              declineButtonText=""
              cookieName="myAppCookieConsent"
              style={{ background: "#0c1732", color: "#FFF", maxWidth:'600px', width:'90%', left:'1%', borderRadius:'2%'}}
              buttonStyle={{ background: "#09b885", color: "#ffff", margin:'0px 10px 20px 10px', fontSize: "14px", borderRadius:'10%' }}
              declineButtonStyle={{ fontSize: "14px", display:'none', background:'#f32b2b', margin:'0px 10px 20px 10px', borderRadius:'10%' }}
              expires={150}
              enableDeclineButton
              onDecline={() => {
                Cookies.remove('functional');
                Cookies.remove('marketing');
              }}
              onAccept={() => {
                Cookies.remove('marketing');
              }}
            >
              <p style={{ marginRight:'auto', color: 'white', font:`normal normal 300 14px/16px ${theme?.Style?.font}`, margin:'0px', paddingLeft:'1%' }}>
                We use first-party cookies for the website to function the right way, and they do not collect any of your personally identifiable data. Mw Cloud related web pages do not use third party cookies. More information on how we use cookies is found here
              </p>
              <div style={{display:'flex', justifyContent:'space-between', marginTop:'3%'}}>
                <p onClick={()=>{redirectToWebsite( lngpreference ? theme?.Footer?.CookiePolicyNo : theme?.Footer?.CookiePolicy)}} style={{cursor:'pointer', marginRight:'auto', margin:'0px', color: '#09b885', font:`normal normal 300 14px/20px ${theme?.Style?.font}`, paddingLeft:'1%' }}>Cookie Policy</p>

                {/* <p onClick={()=>{openConsentForm()}} style={{cursor:'pointer', marginRight:'auto', margin:'0px', color: '#09b885', font:`normal normal 300 14px/20px ${theme?.Style?.font}`, paddingLeft:'1%' }}>Manage </p> */}
              </div>
            </CookieConsent>
        )
      }
      { open && (
        <ConsentForm handleopen={open} closeconsentform={handleCloseConsentForm} handleAccept={handleAccept} handleDecline={handleDecline} />
      )}
      <div className="App">
        <PageContainer />
      </div>
    </MsalProvider>
  );
}

export default App;
