import axios from "axios";
import {getApiConfig, getApiUrl, getIdToken, msalResult } from "../authConfig";
import { AxiosService } from "./AxiosService";

const { apim } = msalResult;  

export const axiosI = axios.create({
    baseURL: apim?.apiURL,
});

export const GetAllApplications = async (token?: any) => {
    return AxiosService(token, false, false)?.get("/apps").then((r: any) => {
        return r;
    }).catch((r: any) => {
        if(r.response.status === 401){
          return r.response.status;
        }
    })
}

export const GetDocuments = () => {
    return AxiosService(false, false, false)?.get("/documentations").then((r: any) => {
        return r.data;
    });
}

export const GetUsersIdentity = async (tokenAccess : any) => {
    return AxiosService(tokenAccess, false, false)?.get("me/profile").then((r: any) => {
        return r;
    }).catch((r: any) => {
        return r.response.status;
    })
}

export const SetLanguagePreference = async (language: string, tokenAccess : any) => {
    // const tokenAccess = await getIdToken();
    const r = await AxiosService(tokenAccess, true, false)?.put("me/preferredlanguage", language);
    return r;
}

export const EditAppRegistration = async (data: any, tokenAccess: any) => {
    // const tokenAccess = await getIdToken();
    const r = await AxiosService(tokenAccess, false, true)?.put("apps/edit", data);
    return r;
}

export const ListApplicationTypes =async () => {
    const tokenAccess = await getIdToken();
    const appTypes = await AxiosService(tokenAccess, false, false).get("apptypes").then((r: any) => {
        return r;
    }).catch((error: any) => {
        return error;
    });
    return appTypes;
}

export const AddNewApplication =async (newApp:any) => {
    const tokenAccess = await getIdToken();
    const payload = await AxiosService(tokenAccess, false, true)?.post("apps/add", newApp).then((res: any) => {
        return res;
    }).catch((error: any) => {
        return error;
    });
    return payload;    
}

export const deleteApplication =async (id:any) => {
    const tokenAccess = await getIdToken();
    const payload = await AxiosService(tokenAccess, false, true)?.delete(`apps/${id}`).then((res: any) => {
        return res;
    }).catch((error: any) => {
        return error;
    });
    return payload;    
}

export const fetchPackages =async (newApp:any) => {
    const tokenAccess = await getIdToken();
    const payload = await AxiosService(tokenAccess, false, false)?.get("AccessPackages").then((res: any) => {
        return res;
    }).catch((r: any) => {
        return r.response.status;
    })
    return payload;    
}

export const getThemeDetails =async () => {
    const tokenAccess = await getIdToken();
    
    const currentUrl = window.location.href;
    // Determine the environment based on the URL
    
    const appTypes = await axiosI.get(getApiUrl(currentUrl), {
        headers: {
            Authorization : "Bearer " +tokenAccess
        }
    }).then((r: any) => {
        return r;
    }).catch((error: any) => {
        return error;
    });
    return appTypes;
}
