import { Grid, Paper } from "@mui/material";
import FooterLogo from "../../assets/currentumlogo.png";
import { msalResult } from "../../authConfig";
import { useLanguage } from "../Language/LanguageContext";
export default function Footer() {

  const { theme } = msalResult;

  const {language} = useLanguage();

  const redirectToWebsite = (url: any) => {
    window.open(url, "_blank");
  }

  return (
    <Paper sx={{ width: "100%", height: '315px', bottom: 0, backgroundColor: theme?.Style?.footerbgColor.trim().length > 0 ? theme?.Style?.footerbgColor : "#1D7058",  
      '@media (max-width: 900px)': { 
        height: '390px', 
      } }} 
      component="footer" 
      square 
      variant="outlined">
      <Grid container height={'inherit'} margin={'0px'} display={'flex'}>
          { theme?.Style?.footerMWLogo === true ?
                  <Grid sx={{transform:{md:'translate(-50%, 4.5%)', sm:'translate(-50%, 9%)', xs:'translate(-50%, 4.5%)'},
                    '@media (max-width: 900px)': {
                      transform: 'translate(-50%, 30%)' 
                    }
                  }} 
                  style={{ position: 'absolute', 
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="780.769" height="299.99" viewBox="0 0 585.769 464.99">
                      <path
                        id="Intersection_2"
                        data-name="Intersection 2"
                        d="M2381.458,747.148a672.273,672.273,0,0,0-177.771-313.943l151.2-151.046c124.377,124.148,212.008,285.091,245.287,464.99Zm-367.049,0V485.314q1.84,1.271,3.677,2.56c1.906,1.269,3.812,2.538,5.718,3.935,3.939,2.665,7.879,5.584,11.69,8.631,2.542,1.777,5.082,3.681,7.5,5.711.127.127.381.254.508.381,4.828,3.808,9.657,7.616,14.232,11.678,5.591,4.569,11.054,9.392,16.391,14.342s10.546,9.9,15.629,15.1l-.038.038c8.64,8.632,16.9,17.517,24.9,26.655,5.464,6.347,10.674,12.694,15.883,19.293,4.955,6.347,9.784,12.694,14.485,19.293,0,.127.128.254.255.381,6.989,9.9,13.722,20.182,20.2,30.59a503.047,503.047,0,0,1,29.478,56.23,476.207,476.207,0,0,1,17.789,45.186l.609,1.824Z"
                        transform="translate(-1750.41 -270.159)"
                        fill="#09b885"
                      />
                    </svg>
                  </Grid> :
                  <></>
          }
        <Grid xs={12} pl={{ md:'5%'}} md={6} item sx={{width:'100%', display: {xs:'flex', sm:'flex', md:'flex', lg:'flex'}, left:{sm:'0%', xs:'1%', md:'8%', lg:'12%'}, right:{sm:'0%', xs:'1%', md:'8%', lg:'12%'}, justifyContent:{sm:'space-around', xs:'space-around'}, flexDirection: {md:'column', lg:'column'} }} style={{ top: '6%', zIndex: 1 }}>
          <Grid md={6} display={'flex'} item padding={'10px'} style={{ maxWidth: '100%', height: 'auto', cursor:'pointer' }} >
            <img
              onClick={()=>{redirectToWebsite(theme?.Footer?.ClientUrl)}}
              src={theme ? `${theme?.FileAccess?.baseUrl}${theme?.LandingPage?.logoUrl}?${theme?.FileAccess?.sasToken}` : FooterLogo} 
              alt="logo" 
              style={{ maxWidth: theme?.Style?.footerMaxWidth, height: '100%', objectFit: 'contain' }}
            />
          </Grid>

          <Grid md={6} item display={'flex'} flexDirection={{sm: 'column', xs:'column'}} justifyContent={{sm:'end', xs:'end', md:'start'}} padding={'10px'} sx={{marginTop:{sm:'0px', xs:'0px', md:'0px'}}} style={{ color: 'white', font: theme?.Style?.font, fontWeight:'600', fontSize:'24px' }}>
            <Grid item display={'flex'} flexDirection={{sm:'column', xs:'column'}} justifyContent={{sm:'center', xs:'center'}}>
              <p style={{font:`normal normal bold 26px/30px ${theme?.Style?.font}`, margin:'0px', display:'flex', justifyContent:'start', textAlign:'left' }}> 
              {theme?.Footer?.TagLine}
              </p>
            </Grid>
          </Grid>

        </Grid>
        <Grid xs={12} md={6} item>
          <Grid item position={'relative'} marginTop={{md:'6%', sm:'5%', xs:'5%'}} paddingLeft={{sm:'5%', xs:"5%"}} sx={{
            '@media (max-width: 900px)': {
              paddingLeft: '15%' 
            }
          }} >
            <Grid xs={12} sm={12} md={12} display={'flex'} item paddingLeft={{md:'0%', sm:'0%', xs:'0%'}}>
              <h2 style={{margin:'0px', font:`normal normal bold 20px/30px ${theme?.Style?.font}`, color:'#ffff'}}>Contact</h2>
            </Grid>
            <Grid width={'100%'} md={12} display={'flex'} marginTop={'2%'} item>
              <Grid md={6} width={'100%'} marginRight={'2%'} item>
                  {
                    theme?.Footer?.Email.trim().length > 0 ?
                      <Grid md={6} xs={12} item maxWidth={'100% !important'} textAlign={'left'}>
                        <div style={{display:'flex'}}>
                          <h5 style={{margin:'0px', font:`normal normal 500 16px/28px ${theme?.Style?.font}`, color:'#ffff',fontWeight:'400'}}>E-mail</h5>
                        </div>
                    
                          <div style={{display:'flex'}}>
                            <h5 style={{margin:'0px', color:'#ffff', font:`normal normal 300 15px / 28px ${theme?.Style?.font}`}}> { theme ? theme?.Footer?.Email : '' }</h5>
                          </div>  
                      </Grid>
                      :
                    <></>
                  }
                  {
                    theme?.Footer?.Address.trim().length > 0 ?
                      <Grid md={6} xs={12} item maxWidth={'100% !important'} textAlign={'left'}>
                        <div style={{display:'flex'}}>
                          <h5 style={{margin:'0px', font:`normal normal 500 16px/28px ${theme?.Style?.font}`, color:'#ffff', fontWeight:'400'}}>Visiting Address</h5>
                        </div>
                        <Grid item style={{display:'flex'}}>
                          <h5 style={{margin:'0px', color:'#FFFF', opacity:'0.8', font:`normal normal 300 15px / 28px ${theme?.Style?.font}`}}>{theme ? theme?.Footer?.Address : ''}</h5>
                        </Grid>
                        <div style={{display:'flex'}}>
                        </div>
                      </Grid> :
                      <></>
                  }
              </Grid>
              <Grid md={6} width={'100%'} item>
                {
                  theme?.Footer?.Phone.trim().length > 0 ?
                  <Grid md={4} xs={12} item maxWidth={'100% !important'} textAlign={'left'}>
                    <div style={{display:'flex'}}>
                      <h5 style={{margin:'0px', font:`normal normal 500 16px/28px ${theme?.Style?.font}`, color:'#ffff', fontWeight:'400'}}>Phone</h5>
                    </div>
                    <div style={{display:'flex'}}>
                      <h5 style={{margin:'0px', color:'#ffff', font:`normal normal 300 15px / 28px ${theme?.Style?.font}`}}>{theme ? theme?.Footer?.Phone : ''}</h5>
                    </div>
                  </Grid> :
                  <></>
                }
                {
                  theme?.Footer?.MailingAddress.trim().length > 0 ?
                  <Grid md={4} xs={12} item maxWidth={'100% !important'} textAlign={'left'}>
                    <div style={{display:'flex'}}>
                      <h5 style={{margin:'0px', font:`normal normal 500 16px/28px ${theme?.Style?.font}`, color:'#ffff',  fontWeight:'400'}}>Mailing Address</h5>
                    </div>
                    <div style={{display:'flex'}}>
                      <h5 style={{margin:'0px', color:'#FFFF', opacity:'0.8', font:`normal normal 300 15px / 28px ${theme?.Style?.font}`}}>{theme ? theme?.Footer?.MailingAddress : ''}</h5>
                    </div>       
                  </Grid> :
                  <></>                  
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item width={'100%'} zIndex={1} sx={{display:{xs:'flex', sm:'flex', md:'flex', lg:'flex'}}} style={{ color: 'white', fontFamily: theme?.Style?.font, fontWeight:'600', fontSize:'24px', justifyContent:'end' }}>
          <Grid item width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'end'}>
            <p onClick={()=>{redirectToWebsite(theme?.Footer?.CopyRightUrl)}} style={{cursor:'pointer', marginRight:'1%', color: 'white', font:`normal normal 300 12px/13px ${theme?.Style?.font}`, paddingLeft:'5%' }}> &copy; Malthe Winje Automation All Rights Reserved</p>
                <hr style={{margin:'5px 0px 0px 0px', height:'30%'}} />
            <p onClick={()=>{redirectToWebsite(language === "en" ? theme?.Footer?.PrivacyPolicy : theme?.Footer?.PrivacyPolicyNo)}} style={{cursor:'pointer', marginRight:'1%', color: 'white', font:`normal normal 300 12px/13px ${theme?.Style?.font}`, paddingLeft:'1%' }}>Privacy Policy</p>
                 <hr style={{margin:'5px 0px 0px 0px', height:'30%'}} />
            <p onClick={()=>{redirectToWebsite( language === "en" ? theme?.Footer?.CookiePolicy : theme?.Footer?.CookiePolicyNo)}} style={{cursor:'pointer', marginRight:'auto', color: 'white', font:`normal normal 300 12px/13px ${theme?.Style?.font}`, paddingLeft:'1%' }}>Cookie Policy</p>

          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
