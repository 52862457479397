import { Alert, Backdrop, CircularProgress, Fade, Grid, MenuItem, Modal, Select, SelectChangeEvent, css, styled } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AddNewApplication, EditAppRegistration, GetUsersIdentity, ListApplicationTypes, deleteApplication } from '../../services/ApiService';
import { Close, Done } from '@mui/icons-material';
import { InputComponent } from '../Inputs/InputComponent';
import { SelectionInput } from '../Select/SelectionInput';
import { SelectionInputArray } from '../Select/SelectionInputArray';
import { getIdToken, msalResult } from '../../authConfig';

interface IFormInput {
    ApplicationName: string;
    ApplicationId: string;
    Logo: File;
    TypeId: string;
    LocalApplicationUrl: string;
    DeviceId: string;
    Customer: string;
    SecurityGroup: string;
  }

  interface IformInput {
    ApplicationName: string;
    Logo: File;
    TypeId: string;
    LocalApplicationUrl: string;
    DeviceId: string;
    Customer: string;
    SecurityGroup: string;
  }
export const EditApplicationCard = ({equipDetails, handleModalClose, reload, newApp, securityGrp, err}: any) => {
    const { register, handleSubmit, setValue } = useForm<IFormInput>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState<any>();
    const [typeId, setTypeId] = useState('');
    const [customer, setCustomer] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [appName, setAppName] = useState('');
    const [applicationTypes, setApplicationTypes] = useState([]);
    const [customerlist, setCustomerlist] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(false);
    const [reporting, setReporting] = useState('hidden');
    const [abbrvName, setabbrvName] = useState(null);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [secrtyGrp, setSecrtyGrp] = useState<any>();
    const [secId, setSecId] = useState<any>();
    const [isAdmin, setIsAdmin] = useState(false);
    let grp: any = [];

    const [open, setOpen] = useState(false);
    const [openError, setErrorOpen] = useState(false);
    const [errorOccured, setErrorOccured] = useState<boolean>();
    const [url, setUrl] = useState('');
    const [errorUrl, setErrorUrl] = useState<boolean>();
    const [hidden, setHidden] = useState<boolean>();

    const { theme } = msalResult;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleErrorClose = () => setErrorOpen(false);

    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      fontFamily: theme?.Style?.font
    };
    const blue = {
      200: '#99CCFF',
      300: '#66B2FF',
      400: '#3399FF',
      500: '#007FFF',
      600: '#0072E5',
      700: '#0066CC',
    };
    
    const grey = {
      50: '#F3F6F9',
      100: '#E5EAF2',
      200: '#DAE2ED',
      300: '#C7D0DD',
      400: '#B0B8C4',
      500: '#9DA8B7',
      600: '#6B7A90',
      700: '#434D5B',
      800: '#303740',
      900: '#1C2025',
    };
    
    const ModalContent = styled('div')(
      ({ theme }) => css`
        font-weight: 500;
        text-align: start;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: hidden;
        background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border-radius: 8px;
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0 4px 12px
          ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
        padding: 24px;
        color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
    
        & .modal-title {
          margin: 0;
          line-height: 1.5rem;
          margin-bottom: 8px;
        }
    
        & .modal-description {
          margin: 0;
          line-height: 1.5rem;
          font-weight: 400;
          color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
          margin-bottom: 4px;
        }
      `,
    );

    const StyledBackdrop = styled(Backdrop)`
      z-index: -1;
      position: fixed;
      inset: 0;
      background-color: rgb(0 0 0 / 0.5);
      -webkit-tap-highlight-color: transparent;
    `;

    const fileInput = useRef<any>();

    const fetchAppData = async () => {
      const res = await ListApplicationTypes();
      if(res === 401){
        handleModalClose();
        err = true;
      }
      setLoading(false);
       
      //  res?.data.map(async (r: any) => {
       
      //   if(r?.abbreviation === "CC" || r?.abbreviation === "R"){
      //     s.push(r);
      //   }          
      //  });
        setApplicationTypes(res?.data);
       return;
    };

    const fetchProfile = async () => {
      const tokenAccess = await getIdToken();
      return GetUsersIdentity(tokenAccess).then((r: any) => {
        if(r?.data?.isAdmin === true){
          setIsAdmin(true);
        }
      });
    };

    useEffect(() => {
      setLoading(false);
      // setReporting('hidden');
      if (!applicationTypes?.length) {
        fetchAppData();
        fetchProfile();
      }
      setReporting( (equipDetails?.typeName === "Reports" || equipDetails?.typeName === "Docs") ? 'hidden' : 'text' )
    }, [applicationTypes?.length]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];    
        if (file && file.type === 'image/png') {
          resizeImage(file)
            .then((resizedImage) => {
              setSelectedImage(resizedImage);
            })
            .catch((error) => {
              alert('Error resizing image. Please try again.');
            });
        } else {
          alert('Please select PNG Image');
        }
      };
    
    const resizeImage = (file: File): Promise<File> => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d')!;
  
              const targetWidth = 215;
              const targetHeight = 215;
  
              canvas.width = targetWidth;
              canvas.height = targetHeight;
  
              ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
  
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const resizedFile = new File([blob], file.name, {
                      type: 'image/png',
                      lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                  } else {
                    reject('Error creating blob from resized image');
                  }
                },
                'image/png',
                1
              );
            };
  
            image.onerror = () => {
              reject('Error loading image for resizing');
            };
  
            image.src = event.target.result as string;
          } else {
            reject('Error reading image as data URL');
          }
        };
  
        reader.onerror = () => {
          reject('Error reading image as data URL');
        };
  
        reader.readAsDataURL(file);
      });
    };

    const handleChange = (event: SelectChangeEvent<typeof typeId>, key: any) => {       
      applicationTypes.map((r: any) => {
        void(event.target.value === r.id ? setabbrvName(r?.abbreviation): null);
      });

      applicationTypes.map((r: any) => {
        if(r?.id === event.target.value){
           if(r?.abbreviation === "CC"){
            setReporting('text');
            setHidden(true)
           } else if(r?.abbreviation === "R" || r?.abbreviation === "D"){
            setReporting('hidden')
            setValue("DeviceId", "");
            setHidden(false)
           }
        }
      })

      setTypeId(event.target.value);
      setValue('TypeId', event.target.value);
      setAppName(key?.props?.children)
    };

    const handleSecurityChange = (evt: SelectChangeEvent<typeof typeId>, key: any) => {
      setSecId(evt.target.value);
    }

    const handleCustomerSelectChange = (event: SelectChangeEvent<typeof typeId>) => { 
      setSelectedCustomer(true);
      setCustomerId(event.target.value);
    }; 

    const securityGrpArr = (ind: any) => {
      securityGrp?.securityGroups.map((r: any, index: any) => {  
        grp = [];
        if(r?.customer === ind){
          grp.push(r)
          setSecrtyGrp(grp);
          // setValue('SecurityGroup',r.id);
        }

        if(grp.length === 1){
          setSecId(grp[0].id)
        }
        // return void(index === ind ? setValue('SecurityGroup',r.id) : null);
      });
    }
    
    const handleCustomerNameSelectChange = (event: SelectChangeEvent, key: any) => { 
      let ind = event.target.value;
      setCustomerName(key?.props?.children);
      setCustomerId(ind);
      setValue('Customer', key?.props?.children)
      securityGrpArr(key?.props?.children);
      setSelectedCustomer(true);
      setSelectCustomer(true) // update this
    };
    
    const changeHomepageUrl = (event: any) => {
      let string = event.target.value.trim();
      setErrorUrl(false)
      if(isValidUrl(string)){
        setValue('LocalApplicationUrl', string);
      } else {
        setErrorUrl(true)
      }
    };

    const isValidUrl = (url: string): boolean => {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      // const urlRegex = /^(ftp|http|https):\/\/([^\/?#]+)(?:\/(?:[^\/?#]*))*\/?$/;
      return urlRegex.test(url);
    };



    const onSubmit:SubmitHandler<IFormInput> = async (data: IFormInput) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('ApplicationName', data.ApplicationName);
        formData.append('ApplicationId', data.ApplicationId);
        formData.append('Logo', selectedImage);
        formData.append('LocalApplicationUrl', data.LocalApplicationUrl);
        formData.append('DeviceId', data.DeviceId);
        formData.append('Customer', data.Customer);
        formData.append("TypeId", typeId);
        const tokenAccess = await getIdToken();
        return EditAppRegistration(formData, tokenAccess).then((r: any) => {
            if(r?.response?.status === 500){
              setLoading(false);
              setErrorOpen(true);
              setErrorOccured(true);
            } else if(r?.response?.status === 200 || r?.response?.status === 202 || r?.status === 202 || r?.status === 200){
              setErrorOpen(true);
              setErrorOccured(false);
              reload(true);
              setLoading(false);
            }   
            
        }).catch((err: any) => {
            setLoading(false);
            setSuccess(false)
            setErrorOpen(true);
            setErrorOccured(true);
            setError(err?.response?.data?.error)
        }) 
    };

    const onSubmitNewApp:SubmitHandler<IFormInput> = (data: IFormInput) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('ApplicationName', data.ApplicationName);
      formData.append('LocalApplicationUrl', data.LocalApplicationUrl);
      formData.append('Logo', selectedImage);
      formData.append("TypeId", typeId);
      formData.append('DeviceId', data.DeviceId)
      formData.append('Customer', customerName);
      // formData.append('SecurityGroup', data.SecurityGroup);
      formData.append('SecurityGroup', secId);

      return AddNewApplication(formData).then((r: any) => {
          setLoading(false)
          if(r?.response?.status === 500){
            setLoading(false);
            setErrorOpen(true);
            setErrorOccured(true);
          } else if(r?.response?.status === 200 || r?.response?.status === 202 || r?.status === 202 || r?.status === 200){
            setErrorOpen(true);
            setErrorOccured(false);
            reload(true);
            setLoading(false);
          }         
      }).catch((err: any) => {
          setLoading(false);
          setSuccess(false)
          setError(err?.response?.data?.error)
      }) 
    };

    const onDelete = () => {
      handleOpen();    
    }

    const onDeleteApplication = () => {
      setLoading(true);
      deleteApplication(equipDetails?.applicationId).then((r: any) => {
        if(r.status === 202 || r.status === 200){
          handleClose();
          handleModalClose();
          setLoading(false);
          reload(true);
        } else if (r?.response?.status === 401){
          err(true);
          handleClose();
          handleModalClose()
          setLoading(false);
        }
      })
    }

    const onCancelOperation = () => {
      handleClose()
    }

    const onSuccessOperation = () => {
      handleClose();
      handleModalClose();
    }

    const onErrorOperation = () => {
      handleErrorClose();
    }

    useEffect(() => {
        newApp ||
        setValue('ApplicationId', equipDetails?.applicationId || '');
        setValue('ApplicationName', equipDetails?.displayName || '');
        setValue('TypeId', equipDetails?.typeId || '');
        setValue('LocalApplicationUrl', equipDetails?.applicationUrl || '');
        setValue('DeviceId', equipDetails?.deviceId || '');
        setValue('Customer', equipDetails?.customer || '');
        setValue('SecurityGroup', equipDetails?.applicationId || '');
        setTypeId(equipDetails?.typeId);
        setCustomerlist(securityGrp?.customers);
        securityGrp?.customers.map((r: any, ind: any) => {
          if(r === equipDetails?.customer){
            setCustomerId(ind)
            securityGrpArr(equipDetails?.customer);
          }
        })

    }, [setValue, equipDetails, newApp]);


  return (
    <Grid container>
        <Grid container>
            <Grid item display={'flex'} flexDirection={'column'} justifyContent={'space-between'} paddingRight={'16px'} marginTop={'18px'} paddingLeft={'16px'} width={'100%'}>
                <Grid sx={{cursor:'pointer'}} marginLeft={'auto'}>
                     <Close onClick={handleModalClose}/>
                </Grid>    
                <Grid>
                  { newApp ?
                    <h1 style={{marginLeft:"32px", font:`normal normal bold 24px/16px ${theme?.Style?.font}`}}>Add New Application</h1> 
                      :
                    <h1 style={{marginLeft:"27px", font:`normal normal bold 24px/26px ${theme?.Style?.font}`}}>Edit Application Details</h1> 
                  }
                </Grid>           
            </Grid>
        </Grid>
        {
            success ?
            <Grid container width={'100%'} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} marginTop={'16px'} bgcolor={'#e0e0e0'} >
                    <Alert severity="success">Information Updated Succesfully!!</Alert>
                </Grid>
                <Grid display={'flex'} justifyContent={'center'} marginTop={'16px'}>
                    <Done onClick={handleModalClose} style={{backgroundColor:"#2a9d8f", padding:"8px", borderRadius:"5px", color:"white", cursor:'pointer'}}/>
                </Grid>
            </Grid>
            
            : 
            <form onSubmit={handleSubmit(newApp ? onSubmitNewApp : onSubmit)} style={{ display:"flex", flexDirection:"column", width:"100%",padding:"16px" }}>

                <input name="photo" type="file" accept="image/png" onChange={(event) => handleImageChange(event)} ref={fileInput} style={{display: 'none' }} />
                <div  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px dashed #ddd',
                    borderRadius: '5px',
                    padding: '30px 0px',
                    cursor: 'pointer',
                    margin:"10px 52px",
                    background: "#f3f3f3",
                  }} className='upload-btn' onClick={() => fileInput?.current?.click()} >
                  <span style={{ fontSize: '16px', color: '#0c1732', marginBottom: '5px', font:`normal normal 700 16px/24px ${theme?.Style?.font}` }}>Select an Image (PNG)</span>
                  <span style={{ fontSize: '12px', color: '#0c1732', font:`normal normal 12px/24px ${theme?.Style?.font}`}}>Max size: 215x215 pixels</span>
                </div>

                {
                    selectedImage && (
                        <Grid display={'flex'} justifyContent={'center'}>
                           <img src={URL.createObjectURL(selectedImage)} alt="" width="150px" height="150px" />
                        </Grid>
                    )
                }

                {
                    !selectedImage && equipDetails?.imageUrl &&(
                        <Grid display={'flex'} justifyContent={'center'}>
                           <img src={equipDetails?.imageUrl} alt="" width="150px" height="150px" />
                        </Grid>
                    )
                }

                {
                  newApp ? 
                  <SelectionInputArray
                    typeId={customerId}
                    handleChange = {handleCustomerNameSelectChange}
                    applicationTypes= {securityGrp?.customers}
                    label={"Select Customer Name"}
                    requiredStatus = {true}
                  />
                  :
                  <SelectionInputArray
                    typeId={customerId}
                    handleChange = {handleCustomerNameSelectChange}
                    applicationTypes= {customerlist}
                    label={"Select Customer Name"}
                    requiredStatus = {true}
                  />
                }

                {
                  selectCustomer || secrtyGrp ? 
                  <Grid>
                    {
                      newApp ? 
                      <SelectionInput
                        typeId={secId}
                        handleChange = {handleSecurityChange}
                        applicationTypes= {secrtyGrp}
                        label={"Select Access Group"}
                        requiredStatus = {true}
                      />
                      :
                      <SelectionInput
                        typeId={secId}
                        handleChange = {handleSecurityChange}
                        applicationTypes= {secrtyGrp}
                        label={"Select Access Group"}
                        requiredStatus = {true}
                      />
                    }
                  </Grid> 
                  :
                  <></>
                }

                {
                  newApp ? 
                  <SelectionInput
                    typeId={typeId}
                    handleChange = {handleChange}
                    applicationTypes= {applicationTypes}
                    label={"Select Application Type"}
                    requiredStatus = {true}
                  />
                  :
                  <SelectionInput
                    typeId={typeId}
                    handleChange = {handleChange}
                    applicationTypes= {applicationTypes}
                    label={"Select Application Type"}
                    requiredStatus = {true}
                    disp = {equipDetails ? true : false }
                  />
                }

                <InputComponent
                  label="Application Name"
                  type="text"
                  register = {register}
                  id="applicationName"
                  name="ApplicationName"
                  requiredStatus = {true}
                />
                
                {
                   newApp ? 
                    <InputComponent
                      label="Device Id"
                      type={reporting}
                      register = {register}
                      id="deviceId"
                      name="DeviceId"
                      requiredStatus = {hidden ? true : false}
                    />
                  :
                    <InputComponent
                      label="Device Id"
                      type={reporting}
                      register = {register}
                      id="deviceId"
                      name="DeviceId"
                      requiredStatus = {hidden ? true : false}
                    />
                }

                {
                  newApp && selectedCustomer ? 
                    <InputComponent
                      label="Url"
                      type="text"
                      register={register}
                      id="baseUrl"
                      name="LocalApplicationUrl"
                      onChange={changeHomepageUrl}
                      requiredStatus = {true}
                    />
                  :
                    <InputComponent
                      label="Url"
                      type="text"
                      register={register}
                      id="baseUrl"
                      name="LocalApplicationUrl"
                      onChange={changeHomepageUrl}
                      requiredStatus = {true}
                    />
                    
                }

                {errorUrl && <div style={{ color: 'red', fontFamily: theme?.Style?.font, fontSize:'70%' }}>Please enter valid url</div>}


                {loading ? 
                    <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={'16px'} bgcolor={'#e0e0e0'}>
                        <h4 style={{margin:'8px', font:`normal normal 400 16px/18px ${theme?.Style?.font}`}}>Please Wait....</h4>
                        <CircularProgress size={'20px'} style={{marginTop:"8px"}}/>
                    </Grid>
                    :
                    <Grid item display={'flex'} justifyContent={'space-around'}>
                      { newApp && isAdmin ? <></> : 
                        <input onClick={onDelete} disabled={loading} style={{padding:"12px 24px", font:`normal normal 400 14px/16px ${theme?.Style?.font}`, borderRadius:"24px", marginTop:"20px", backgroundColor:"#f32b2b", color:"white", cursor:"pointer", border:"none", }} value="Delete" type='Button'/>
                      }
                      <input disabled={loading || errorUrl} style={{padding:"12px 24px", font:`normal normal 400 14px/16px ${theme?.Style?.font}`, borderRadius:"24px", marginTop:"20px", backgroundColor:"#09b885", color:"white", cursor:"pointer", border:"none", }} type="submit" name="Submit"/>
                    </Grid>
                }
                {
                    error ?
                        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={'16px'} bgcolor={'#e0e0e0'}>
                            <h4 style={{margin:'8px'}}>{error}</h4>
                        </Grid> : <></>
                }
            </form>
        }

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <h2 id="transition-modal-title" className="modal-title">
              Are you sure you want to delete : <br/>
              <span style={{color:'#3312d4'}}> {equipDetails?.displayName}</span>
            </h2>
            <Grid item display={'flex'} justifyContent={'space-around'}>
              {
                loading ? 
                <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={'16px'}>
                    <h4 style={{margin:'8px', font:`normal normal 400 16px/18px ${theme?.Style?.font}`}}>Please Wait....</h4>
                    <CircularProgress size={'20px'} style={{marginTop:"8px"}}/>
                </Grid> 
              :
              <Grid item display={'flex'} flexDirection={'row'} justifyContent={'space-around'} width={'100%'}> 
                <input onClick={onDeleteApplication} disabled={loading} style={{padding:"12px 24px", font:`normal normal 400 14px/16px ${theme?.Style?.font}`, borderRadius:"24px", marginTop:"20px", backgroundColor:"#f32b2b", color:"white", cursor:"pointer", border:"none", }} value="Delete" type='Button'/>
                <input onClick={onCancelOperation} disabled={loading} style={{padding:"12px 24px", font:`normal normal 400 14px/16px ${theme?.Style?.font}`, borderRadius:"24px", marginTop:"20px", backgroundColor:"#09b885", color:"white", cursor:"pointer", border:"none", }} type="Button" name="Cancel" value="Cancel"/>
              </Grid>

              }
            </Grid>
          </ModalContent>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openError}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={openError}>
          <ModalContent sx={style}>
            <h2 id="transition-modal-title" className="modal-title">
              {
                errorOccured ? "An Error Occured " : "Success"
              }
              <br/>
              <span style={{color:'#ff0000'}}> 
              { errorOccured ? "Information creation failed" : "Information created successfully"}                
              </span>
            </h2>
            <Grid item display={'flex'} justifyContent={'space-around'}>
              <input onClick={errorOccured ? onErrorOperation : onSuccessOperation} disabled={loading} style={{padding:"12px 24px", font:`normal normal 400 14px/16px ${theme?.Style?.font}`, borderRadius:"24px", marginTop:"20px", backgroundColor:"#09b885", color:"white", cursor:"pointer", border:"none", }} type="Button" name="Cancel" value="OK"/>
            </Grid>
          </ModalContent>
        </Fade>
      </Modal>
        
    </Grid>
  )
}
