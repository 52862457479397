import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { initializeMsal, msalResult } from '../../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

export const Err = ({isOpen}: any) => {

    const [open, setOpen] = useState(isOpen);
    const { t } = useTranslation();

    useEffect(() => {
        const initialize = async () => {
          try {
            await initializeMsal();
            // setLoading(false);
          } catch (error) {
            console.error('Error during MSAL initialization:', error);
            // setLoading(false);
          }
        };
    
        initialize();
      }, []);
    
      const instance  = msalResult?.instance;
      const msalInstance = new PublicClientApplication(instance);

    const handleLogout = () => { 
      setOpen(false);    
      
      msalInstance.logoutRedirect();
    }
  
    return (
      <div>
        <Dialog
          open= {open}
          onClose={handleLogout }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Session Update
            </DialogTitle> 
        
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Session Expired
                  <br></br>
              </DialogContentText>
          </DialogContent>
  
          <DialogActions>
                <Button onClick={handleLogout} autoFocus> Logout </Button> 
          </DialogActions>
          
        </Dialog>
      </div>
    )
}
